import { Stack, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "5px",
        width: "350px",
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            //marginRight: "20px",
            gap: "5px",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: entry.color,
              marginRight: "5px",
              boxShadow: "0px 0px 5px 0px rgba(1,0,0,0.1)",
            }}
          ></div>
          <span
            style={{
              color: "black",
              minWidth: "55px",
              textAlign: "center",
              paddingLeft: "1px",
            }}
          >
            {entry.value}
          </span>
        </div>
      ))}
    </div>
  );
};

const data = [
  { name: "0-20", value: 20 },
  { name: "20-40", value: 30 },
  { name: "40-60", value: 25 },
  { name: "60-80", value: 15 },
  { name: "80-100", value: 10 },
];

const COLORS = ["#f6eef2", "#687495", "#20B2AA", "#f79bbc", "#233a5a"];

const CustomPieChart = ({ data, title, colors = COLORS }) => {
  return (
    <Stack alignItems={"center"} p={2}>
      <PieChart
        width={350}
        height={260}
        style={{ marginTop: 0, alignItems: "center" }}
      >
        <Pie
          data={data}
          cx={175}
          cy={100}
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index] ?? COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip />
        <Legend content={<CustomLegend />} />
      </PieChart>
      {title && (
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            //   color: "black",
            //   fontWeight: "bold",
            // marginTop: 10,
          }}
        >
          {title}
        </Typography>
      )}
    </Stack>
  );
};

export default CustomPieChart;
