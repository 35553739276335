import { cloneElement } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import Logo from "../../components/logo";

const termsData = [
  {
    title: "Acceptance of Terms",
    content:
      'By accessing and using our PeaceTwogether Corporate Mental Wellness Application ("Application"), you agree to abide by these Terms and Conditions. If you do not agree with any part of these terms, please do not use the Application.',
  },
  {
    title: "Subscription and Access",
    content:
      "a. The PeaceTwogether Application is designed for corporate use and requires a subscription. By subscribing, you gain access to the features and content offered by the PeaceTwogether Application. b. Subscriptions are subject to payment and renewal terms as specified during the subscription process.",
  },
  {
    title: "Intellectual Property",
    content:
      "All content, materials, and intellectual property rights within the PeaceTwogether Application are owned by us or our licensors. You agree not to reproduce, distribute, modify, or create derivative works based on the PeaceTwogether Application without our express written consent.",
  },
  {
    title: "Data Privacy",
    content:
      "a. Your use of the PeaceTwogether Application may involve the collection and processing of personal and corporate data. We are committed to protecting your privacy and will handle your data in accordance with our Privacy Policy. b. You agree to the collection, storage, and processing of your data as outlined in our Privacy Policy.",
  },
  {
    title: "Prohibited Activities",
    content:
      "You agree not to: a. Use the PeaceTwogether Application for any unlawful purpose or in a way that violates these Terms. b. Attempt to gain unauthorized access to the PeaceTwogether Application, its servers, or any related systems. c. Share your PeaceTwogether login credentials with unauthorized individuals or entities.",
  },
  {
    title: "Limitation of Liability",
    content:
      "a. We strive to provide accurate and reliable content within the PeaceTwogether Application. However, we do not guarantee the accuracy, completeness, or reliability of any content. b. You use the PeaceTwogether Application at your own risk. We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the PeaceTwogether Application.",
  },
  {
    title: "Termination",
    content:
      "We reserve the right to terminate or suspend your access to the PeaceTwogether Application at our discretion, without notice or liability, if you violate these Terms or engage in activities that are harmful to other users or us.",
  },
  {
    title: "Modification of Terms",
    content:
      "We may update or modify these Terms at any time. It is your responsibility to review these Terms periodically. Your continued use of the PeaceTwogether Application after any changes indicates your acceptance of the modified Terms.",
  },
  {
    title: "Governing Law",
    content:
      "These Terms shall be governed by and construed in accordance with the laws of Kolkata, India. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Kolkata, India. By using our PeaceTwogether Corporate Mental Wellness Application, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you have any questions, please contact us at support@peacetwogether.com.",
  },
];

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function CorporateTnC() {
  const navigate = useNavigate();
  return (
    <>
      <ElevationScroll>
        <AppBar
          sx={{
            bgcolor: "white",
            paddingX: { xs: 2, lg: 16 },
            paddingY: { lg: 1 },
          }}
        >
          <Toolbar>
            <Logo />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Stack
        pt={15}
        px={{ lg: 20, md: 5, sm: 5, xs: 5 }}
        paddingBottom={{ lg: 5, md: 5, sm: 5, xs: 5 }}
        alignContent={"center"}
        spacing={2}
      >
        <Typography variant="h5" fontWeight={"bold"} gutterBottom>
          Terms and Conditions
        </Typography>
        {termsData.map((item, index) => (
          <section key={index}>
            <Typography>
              {index + 1}. <b>{item.title}</b>
            </Typography>
            <Typography>{item.content}</Typography>
          </section>
        ))}
      </Stack>
      <Stack
        direction={{ lg: "row" }}
        alignItems={{
          md: "flex-end",
          xs: "center",
        }}
        sx={{
          justifyContent: "space-between",
          marginBottom: 5,
          paddingX: { xs: 5, lg: 20 },
        }}
      >
        <Stack
          justifyContent={"center"}
          alignItems={{ xs: "center", md: "flex-start" }}
          height={"100px"}
          spacing={2}
        >
          <Logo />
          <Typography color={"rgba(0, 0, 0, 0.57)"}>
            ©Copyright 2023 | Virtue Mind Pvt. Ltd
          </Typography>
        </Stack>

        <Button
          variant="text"
          color="inherit"
          onClick={() => navigate("/corporate/landing")}
          sx={{ color: "rgba(0, 0, 0, 0.57)", textTransform: "none" }}
        >
          Home
        </Button>
      </Stack>
    </>
  );
}
